<template>
  <div>
    <div
      class="fullscreen-bg"
      v-bind:class="{ 'active': showVideo }"
    >
      <video ref="myvideo" playsinline class="fullscreen-bg__video">
          <source :src="backendStorage+'/videos/snow-new.mp4'">
      </video>
    </div>
    <div class="has-text-centered">
      <b-button
        v-bind:key="button.title"
        class="btn"
        type="is-default"
        @click="snow()"
      >
        {{button.title}}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SnowButton',
  data() {
    return {
      showVideo: false,
    };
  },
  props: {
    button: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    backendStorage() {
      return this.$store.getters.backendStorage;
    },
  },
  methods: {
    snow() {
      this.$emit('animationStart');
      this.showVideo = true;
      this.$refs.myvideo.play();
      setInterval(() => {
        this.$emit('animationEnded');
      }, 6000);
    },
  },
};
</script>

<style lang="scss" scoped>
.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
  opacity:0;
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  &.active {
    opacity: 1;
    z-index: 5;
  }
}

.fullscreen-bg__video {
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.btn {
  background: url('/images/button-snow.png');
  background-repeat: no-repeat;
  background-position: center top;
  width: 100%;
  padding-top:100px;
  z-index: 3;
  text-transform: uppercase;
}
</style>
