<template>
  <footer class="p-4">
    <div class="container">
      <div class="columns has-text-centered-mobile">
        <div class="column is-3">
          © BKIS 2024
        </div>
        <div class="column has-text-right-tablet has-text-weight-bold">
          <span class="mr-6 is-hidden-mobile"> </span>
          <br class="is-hidden-tablet">
          <a class="is-uppercase" target="_blank" href="https://www.bkis.sk/informacie-o-spracuvani-osobnych-udajov/">
            Informácie o spracúvaní osobných údajov
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
