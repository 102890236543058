<template>
  <div class="home has-text-homeblack is-family-secondary">
    <section class="hero is-fullheight back">
      <div class="hero-head">
        <Navigation></Navigation>
      </div>
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered is-reversed has-text-centered-mobile">
            <div class="column is-5">
              <div>
                <h2
                  class="is-uppercase
                  is-size-3
                  has-text-weight-light"
                >
                  <strong>Interaktívna ZIMNÁ HRA</strong> pre celú rodinu
                </h2>
                <h1
                  class="
                    is-size-0
                    is-size-2-mobile
                    is-family-primary
                    has-text-homered
                    has-text-weight-bold"
                >
                  ŠKRIATKOVO KÚZLO
                </h1>
                <p
                  class="is-uppercase
                  is-size-3

                  has-text-weight-light"
                >
                v centre Bratislavy
                </p>

              </div>
              <div class="columns mt-3">
                <div class="column">
                  <img src="/images/start-new.png" width="107" alt="Štart hry pri Bibiane">
                  <p class="has-text-weight-bold">
                    Štart hry pri Bibiane
                  </p>
                </div>
                <div class="column">
                  <img src="/images/night.png" alt="Nocna atmosfera" style="margin-top:39px">
                  <p
                    class="has-text-weight-bold"
                  >
                    Odporúčame vo večernej atmosfére
                  </p>
                </div>
                <div class="column">
                  <img src="/images/phone.png" alt="Telefon" style="margin-top:30px">
                    <p class="has-text-weight-bold">
                      Zapni mobilom čarovné inštalácie
                    </p>
                </div>
              </div>

              <blockquote class="is-uppercase is-size">
                Vstúp s mobilom v ruke do sveta škriatkov,<br>
                vôní domova, nezbedností a
                <a href="/#/susienky" class="has-text-homered"> sušienok</a>
              </blockquote>

              <b-button
                class="btn"
                type="is-default"
                tag="router-link"
                :to="{name:'Play'}"
              >
                Hrať hru
              </b-button>

            </div>
            <div class="column is-6">
              <img src="/images/home-dwarf.png" alt="Skriatkove kuzlo" class="image is-fullwidth">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section home-section-padding">
      <div class="container">
        <div class="columns">
          <div class="column is-5 flex-center">
            <img src="/images/sitting-dwarf.png" alt="Skriatkove kuzlo">
          </div>
          <div class="column">
              <h2 class="is-size-1 is-family-primary has-text-homered has-text-weight-bold">
                Pomôž škriatkovi<br>z topánky nájsť<br>vôňu domova
              </h2>
              <div class="columns mt-6">
                <div class="column is-offset-2">
                  <p class="is-family-secondary">
                    Každý škriatok sa túži usadiť a cítiť sladkú vôňu domova.<br>
                    <strong>Lenže, ktorá to je? Ako ju spoznať?</strong><br>
                    Prejdi sa po meste a zaži tajomný svet bratislavských škriatkov.
                    S mobilom v ruke navštíviš osem čarovných okien, kde pomôžeš
                    škriatkovi s hľadaním vytúženej vône. Zasvieť si, otoč kompasom,
                    zaťukaj na okno v správny čas, zisti, čo je trúbšlakčelo,
                    zabojuj s časom a objav tajnú príchuť v maslových sušienkach.<br>
                    <br>
                    <strong>Čakajú ťa nevšedné prekvapenia.</strong>

                  </p>
                </div>
              </div>
          </div>
        </div>
      </div>

    </section>
    <section class="section time">
      <div class="container has-text-centered">
        <p class="flower is-family-secondary">
          <strong class="is-size-4 is-uppercase">
            Hru Škriatkovo kúzlo si môžeš v meste zahrať každý deň od 8:00 do 21:00.
          </strong>
          <br>
          <span class="has-text-weigh-light">
            Na konci hry zistíš, aký je tvoj rodinný škriatok, aj ako s ním udržiavať dobré vzťahy.
          </span>
        </p>
      </div>

    </section>
    <section class="section back">
      <div class="container">
        <div class="columns">
          <div class="column is-3 has-text-centered">
            <img src="/images/compass.png" alt="">
          </div>
          <div class="column is-7 is-family-secondary">
            <h2 class="is-size-1 has-text-homered is-family-primary has-text-weight-bold">
              Trasa hry
            </h2>
            <div class="columns mt-6">
              <div class="column is-offset-1">
                Hra sa začína pri Bibiane a dokopy má 8 zastávok ukrytých v uličkách Starého Mesta.
                Škriatkovo kúzlo a žltá šípka v hre ťa povedú.<br>
                <br>
                <strong>
                  Cestu do rozprávku zvládnu aj menšie deti.
                  Čaká ich 1961 krokov.
                </strong>

              </div>
              <div class="column">
                <h3 class="has-text-weight-bold is-uppercase is-size-4">
                  Dobrý tip priamo od škriatkov
                </h3>
                <p>
                  Hra bude najkrajšia vo večernej atmosfére, lebo mnohé inštalácie svietia. <br>
                  <br>
                  Odporúčame rukavice na dotykový displej a nabitú baterku.
                </p>

              </div>
            </div>
            <div class="columns is-uppercase is-size-4">
              <div class="column is-offset-1 is-4 flex-center-left ">
                <img src="/images/time.png" alt="">
                <p>
                  <strong>
                    45 minút v uličkách Bratislavy
                  </strong>
                </p>
              </div>
              <div class="column is-4 flex-center-left">
                <img src="/images/steps.png" alt="">
                <p><strong>1961</strong> detských krokov<br>
                  <strong>9020</strong> škriatkovských</p>
              </div>
              <div class="column is-4 flex-center-left">
                <img src="/images/head.png" alt="">
                <p>
                  <strong>
                    S PETROU POLNIŠOVOU V UCHU
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div class="column is-2 flex-center">
            <img src="/images/dwarf-kremes.png" alt="">
          </div>
        </div>
      </div>

    </section>
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-7 is-offset-1">
            <h2 class="is-size-1 has-text-homered is-family-primary has-text-weight-bold">
              Ako hrať<br>Škriatkovo kúzlo
            </h2>
            <div class="columns mt-6">
              <div class="column is-10 is-offset-1">
                <p>
                  <strong>Hra sa spúšťa online na tejto stránke.</strong>
                  Potrebuješ k nej smartfón a pripojenie na internet.<br>
                  Na úvod ti do <strong>emailu</strong>
                  pošleme tajný škriatkovský PIN kód, ktorý bude len tvoj.
                  Môžeš ho použiť hocikedy a zažiť s ním hru na tvojom telefóne opakovane.
                  PIN kód sa nepokazí. Má doživotnú záruku.<br>
                  Neboj.<br>
                  <br>
                  <strong>Pri Bibiane</strong> ťa na začiatku trasy nasníma naša GPS.
                  Ďalej ťa už bude navigovať hra, škriatkovia a tvoj inštinkt.<br>
                  <br>
                  Inštalácie v oknách sú interaktívne, tak pozorne sleduj,
                  čo sa deje, môže to súvisieť s tvojou úlohou. <br>
                  <br>
                  <strong>
                    Bude to čarovné.
                  </strong>
                </p>
              </div>
            </div>

          </div>
          <div class="column is-4">
            <h2 class="is-size-1 has-text-homered is-family-primary has-text-weight-bold">
              Otázky<br>k hre
            </h2>
            <div class="columns mt-6">
              <div class="column">
                <p>
                    <strong>
                      Vybije sa mi mobil. Môžem hru dokončiť zajtra?
                    </strong> <br>
                    Áno.<br>
                    <br>
                    <strong>
                      Keď odídem zo stránky skriatkovokuzlo.sk,
                      môžem sa vrátiť naspäť do hry a pokračovať?
                    </strong> <br>
                    Áno, môžeš. Vlastne musíš:) <br>
                    <br>
                    <strong>Bude mi PIN kód fungovať aj zajtra?</strong> <br>
                    Jasnačka, PIN kód ti platí už naveky vekov.<br>
                    <br>
                    <strong>
                      Môžem PIN kód použiť na viacerých mobiloch naraz?
                    </strong> <br>
                    To radšej nie. V hre sa spúšťajú rôzne interakcie,
                    a keby ste mali dvaja rovnaký PIN kód, nefugovali by,
                    čo by bola škoda.<br><br>
                    <strong>Keď v hre niečo zle odkliknem, viem sa vrátiť?</strong>
                    <br>
                    Určite. Do hry sme nakreslili šípku ← späť,
                    s ktorou si každú situáciu v hre môžeš zopakovať.
                </p>
                <b-button
                  class="btn"
                  type="is-default"
                  tag="router-link"
                  :to="{name:'Settings'}"
                >
                  GPS POMOC
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-4 is-offset-1">
            <img src="/images/light.png" alt="">
          </div>
          <div class="column is-7">
            <h2 class="is-size-1 has-text-homered is-family-primary has-text-weight-bold">
              Škriatkovia sa sťahujú <br>do Bratislavy
            </h2>
            <div class="columns mt-6">
              <div class="column is-10 is-offset-1">
                <p>
                  Hru vytvorilo mesto Trnava v roku 2020 s cieľom prebudiť v meste počas
                  adventu vianočnú atmosféru aj bez trhov.
                  Vďaka silnému rozprávkového príbehu, krásnym inštaláciám a s pomocou digitálu,
                  sa to podarilo. <br> <br>
                  <strong>
                    V roku 2024 sa Škriatkovo kúzlo presúva do hlavného mesta.
                    Interaktívna hra pre celú rodinu ťa povedie po uličkách
                    a nádvoriach Bratislavy až k vytúženému cieľu.
                  </strong>
                  <br>
                </p>
                <img src="/images/loga.png" alt="" class="mt-6">
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
    <section class="section back">
      <div class="container">
        <div class="columns">
          <div class="column is-offset-1">
            <h2
              class="is-uppercase
              is-size-3
              has-text-weight-light"
            >
              <strong>Kúsok histórie:</strong>
            </h2>
            <h1 class="is-size-1 has-text-homered is-family-primary has-text-weight-bold">
              Škriatkovia domoví<br>ochraňovali naše príbytky
            </h1>
          </div>
        </div>
        <div class="columns mt-6">
          <div class="column is-4 is-offset-2">
            <p>
              Naši predkovia kedysi verili, že škriatkovia sú zázračné bytosti,
              ktoré ochraňujú dom pred chorobami, strážia špajzu a majetok.
              Dom, do ktorého sa nasťahovali, obývali do konca života.
              Často sa na ich spokojnosť myslelo pri rôznych sviatkoch,
              všeobecne štvrtky a na Štedrý večer, kedy sa im do rôznych
              kútov domu ukladalo jedlo. Do povedomia sa vo veľkom dostali
              vďaka rozvoju slovenskej národnej literatúry v 19. storočí,
              kedy o nich písali mnohí významní autori (napríklad Ján Hollý),
              aj mnohí rozprávkari a zberatelia ľudovej slovesnosti.
            </p>
          </div>
          <div class="column is-4 is-offset-1">
            <p>
              Objavili sa tiež v prepisoch regionálnych povier a povestí,
              ktoré sa neraz líšili  “dedina od dediny”.
              Ako jeden z prvých o domácich škriatkoch písal dokonca Ján Amos Komenský.
            </p>
            <b-button
              class="btn"
              type="is-default"
              tag="router-link"
              :to="{name:'Play'}"
            >
              Hrať hru
            </b-button>
          </div>
        </div>
      </div>

    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue';
import Navigation from '../components/Navigation.vue';

export default {
  name: 'RealHome',
  components: {
    Navigation,
    Footer,
  },
};
</script>
