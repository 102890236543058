var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home has-text-homeblack is-family-secondary"},[_c('section',{staticClass:"hero is-fullheight back"},[_c('div',{staticClass:"hero-head"},[_c('Navigation')],1),_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-centered is-reversed has-text-centered-mobile"},[_c('div',{staticClass:"column is-5"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('b-button',{staticClass:"btn",attrs:{"type":"is-default","tag":"router-link","to":{name:'Play'}}},[_vm._v(" Hrať hru ")])],1),_vm._m(3)])])])]),_vm._m(4),_vm._m(5),_vm._m(6),_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_vm._m(7),_c('div',{staticClass:"column is-4"},[_vm._m(8),_c('div',{staticClass:"columns mt-6"},[_c('div',{staticClass:"column"},[_vm._m(9),_c('b-button',{staticClass:"btn",attrs:{"type":"is-default","tag":"router-link","to":{name:'Settings'}}},[_vm._v(" GPS POMOC ")])],1)])])])])]),_vm._m(10),_c('section',{staticClass:"section back"},[_c('div',{staticClass:"container"},[_vm._m(11),_c('div',{staticClass:"columns mt-6"},[_vm._m(12),_c('div',{staticClass:"column is-4 is-offset-1"},[_c('p',[_vm._v(" Objavili sa tiež v prepisoch regionálnych povier a povestí, ktoré sa neraz líšili “dedina od dediny”. Ako jeden z prvých o domácich škriatkoch písal dokonca Ján Amos Komenský. ")]),_c('b-button',{staticClass:"btn",attrs:{"type":"is-default","tag":"router-link","to":{name:'Play'}}},[_vm._v(" Hrať hru ")])],1)])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"is-uppercase is-size-3 has-text-weight-light"},[_c('strong',[_vm._v("Interaktívna ZIMNÁ HRA")]),_vm._v(" pre celú rodinu ")]),_c('h1',{staticClass:"is-size-0 is-size-2-mobile is-family-primary has-text-homered has-text-weight-bold"},[_vm._v(" ŠKRIATKOVO KÚZLO ")]),_c('p',{staticClass:"is-uppercase is-size-3 has-text-weight-light"},[_vm._v(" v centre Bratislavy ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns mt-3"},[_c('div',{staticClass:"column"},[_c('img',{attrs:{"src":"/images/start-new.png","width":"107","alt":"Štart hry pri Bibiane"}}),_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" Štart hry pri Bibiane ")])]),_c('div',{staticClass:"column"},[_c('img',{staticStyle:{"margin-top":"39px"},attrs:{"src":"/images/night.png","alt":"Nocna atmosfera"}}),_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" Odporúčame vo večernej atmosfére ")])]),_c('div',{staticClass:"column"},[_c('img',{staticStyle:{"margin-top":"30px"},attrs:{"src":"/images/phone.png","alt":"Telefon"}}),_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" Zapni mobilom čarovné inštalácie ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('blockquote',{staticClass:"is-uppercase is-size"},[_vm._v(" Vstúp s mobilom v ruke do sveta škriatkov,"),_c('br'),_vm._v(" vôní domova, nezbedností a "),_c('a',{staticClass:"has-text-homered",attrs:{"href":"/#/susienky"}},[_vm._v(" sušienok")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-6"},[_c('img',{staticClass:"image is-fullwidth",attrs:{"src":"/images/home-dwarf.png","alt":"Skriatkove kuzlo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section home-section-padding"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-5 flex-center"},[_c('img',{attrs:{"src":"/images/sitting-dwarf.png","alt":"Skriatkove kuzlo"}})]),_c('div',{staticClass:"column"},[_c('h2',{staticClass:"is-size-1 is-family-primary has-text-homered has-text-weight-bold"},[_vm._v(" Pomôž škriatkovi"),_c('br'),_vm._v("z topánky nájsť"),_c('br'),_vm._v("vôňu domova ")]),_c('div',{staticClass:"columns mt-6"},[_c('div',{staticClass:"column is-offset-2"},[_c('p',{staticClass:"is-family-secondary"},[_vm._v(" Každý škriatok sa túži usadiť a cítiť sladkú vôňu domova."),_c('br'),_c('strong',[_vm._v("Lenže, ktorá to je? Ako ju spoznať?")]),_c('br'),_vm._v(" Prejdi sa po meste a zaži tajomný svet bratislavských škriatkov. S mobilom v ruke navštíviš osem čarovných okien, kde pomôžeš škriatkovi s hľadaním vytúženej vône. Zasvieť si, otoč kompasom, zaťukaj na okno v správny čas, zisti, čo je trúbšlakčelo, zabojuj s časom a objav tajnú príchuť v maslových sušienkach."),_c('br'),_c('br'),_c('strong',[_vm._v("Čakajú ťa nevšedné prekvapenia.")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section time"},[_c('div',{staticClass:"container has-text-centered"},[_c('p',{staticClass:"flower is-family-secondary"},[_c('strong',{staticClass:"is-size-4 is-uppercase"},[_vm._v(" Hru Škriatkovo kúzlo si môžeš v meste zahrať každý deň od 8:00 do 21:00. ")]),_c('br'),_c('span',{staticClass:"has-text-weigh-light"},[_vm._v(" Na konci hry zistíš, aký je tvoj rodinný škriatok, aj ako s ním udržiavať dobré vzťahy. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section back"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3 has-text-centered"},[_c('img',{attrs:{"src":"/images/compass.png","alt":""}})]),_c('div',{staticClass:"column is-7 is-family-secondary"},[_c('h2',{staticClass:"is-size-1 has-text-homered is-family-primary has-text-weight-bold"},[_vm._v(" Trasa hry ")]),_c('div',{staticClass:"columns mt-6"},[_c('div',{staticClass:"column is-offset-1"},[_vm._v(" Hra sa začína pri Bibiane a dokopy má 8 zastávok ukrytých v uličkách Starého Mesta. Škriatkovo kúzlo a žltá šípka v hre ťa povedú."),_c('br'),_c('br'),_c('strong',[_vm._v(" Cestu do rozprávku zvládnu aj menšie deti. Čaká ich 1961 krokov. ")])]),_c('div',{staticClass:"column"},[_c('h3',{staticClass:"has-text-weight-bold is-uppercase is-size-4"},[_vm._v(" Dobrý tip priamo od škriatkov ")]),_c('p',[_vm._v(" Hra bude najkrajšia vo večernej atmosfére, lebo mnohé inštalácie svietia. "),_c('br'),_c('br'),_vm._v(" Odporúčame rukavice na dotykový displej a nabitú baterku. ")])])]),_c('div',{staticClass:"columns is-uppercase is-size-4"},[_c('div',{staticClass:"column is-offset-1 is-4 flex-center-left"},[_c('img',{attrs:{"src":"/images/time.png","alt":""}}),_c('p',[_c('strong',[_vm._v(" 45 minút v uličkách Bratislavy ")])])]),_c('div',{staticClass:"column is-4 flex-center-left"},[_c('img',{attrs:{"src":"/images/steps.png","alt":""}}),_c('p',[_c('strong',[_vm._v("1961")]),_vm._v(" detských krokov"),_c('br'),_c('strong',[_vm._v("9020")]),_vm._v(" škriatkovských")])]),_c('div',{staticClass:"column is-4 flex-center-left"},[_c('img',{attrs:{"src":"/images/head.png","alt":""}}),_c('p',[_c('strong',[_vm._v(" S PETROU POLNIŠOVOU V UCHU ")])])])])]),_c('div',{staticClass:"column is-2 flex-center"},[_c('img',{attrs:{"src":"/images/dwarf-kremes.png","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-7 is-offset-1"},[_c('h2',{staticClass:"is-size-1 has-text-homered is-family-primary has-text-weight-bold"},[_vm._v(" Ako hrať"),_c('br'),_vm._v("Škriatkovo kúzlo ")]),_c('div',{staticClass:"columns mt-6"},[_c('div',{staticClass:"column is-10 is-offset-1"},[_c('p',[_c('strong',[_vm._v("Hra sa spúšťa online na tejto stránke.")]),_vm._v(" Potrebuješ k nej smartfón a pripojenie na internet."),_c('br'),_vm._v(" Na úvod ti do "),_c('strong',[_vm._v("emailu")]),_vm._v(" pošleme tajný škriatkovský PIN kód, ktorý bude len tvoj. Môžeš ho použiť hocikedy a zažiť s ním hru na tvojom telefóne opakovane. PIN kód sa nepokazí. Má doživotnú záruku."),_c('br'),_vm._v(" Neboj."),_c('br'),_c('br'),_c('strong',[_vm._v("Pri Bibiane")]),_vm._v(" ťa na začiatku trasy nasníma naša GPS. Ďalej ťa už bude navigovať hra, škriatkovia a tvoj inštinkt."),_c('br'),_c('br'),_vm._v(" Inštalácie v oknách sú interaktívne, tak pozorne sleduj, čo sa deje, môže to súvisieť s tvojou úlohou. "),_c('br'),_c('br'),_c('strong',[_vm._v(" Bude to čarovné. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"is-size-1 has-text-homered is-family-primary has-text-weight-bold"},[_vm._v(" Otázky"),_c('br'),_vm._v("k hre ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v(" Vybije sa mi mobil. Môžem hru dokončiť zajtra? ")]),_vm._v(" "),_c('br'),_vm._v(" Áno."),_c('br'),_c('br'),_c('strong',[_vm._v(" Keď odídem zo stránky skriatkovokuzlo.sk, môžem sa vrátiť naspäť do hry a pokračovať? ")]),_vm._v(" "),_c('br'),_vm._v(" Áno, môžeš. Vlastne musíš:) "),_c('br'),_c('br'),_c('strong',[_vm._v("Bude mi PIN kód fungovať aj zajtra?")]),_vm._v(" "),_c('br'),_vm._v(" Jasnačka, PIN kód ti platí už naveky vekov."),_c('br'),_c('br'),_c('strong',[_vm._v(" Môžem PIN kód použiť na viacerých mobiloch naraz? ")]),_vm._v(" "),_c('br'),_vm._v(" To radšej nie. V hre sa spúšťajú rôzne interakcie, a keby ste mali dvaja rovnaký PIN kód, nefugovali by, čo by bola škoda."),_c('br'),_c('br'),_c('strong',[_vm._v("Keď v hre niečo zle odkliknem, viem sa vrátiť?")]),_c('br'),_vm._v(" Určite. Do hry sme nakreslili šípku ← späť, s ktorou si každú situáciu v hre môžeš zopakovať. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4 is-offset-1"},[_c('img',{attrs:{"src":"/images/light.png","alt":""}})]),_c('div',{staticClass:"column is-7"},[_c('h2',{staticClass:"is-size-1 has-text-homered is-family-primary has-text-weight-bold"},[_vm._v(" Škriatkovia sa sťahujú "),_c('br'),_vm._v("do Bratislavy ")]),_c('div',{staticClass:"columns mt-6"},[_c('div',{staticClass:"column is-10 is-offset-1"},[_c('p',[_vm._v(" Hru vytvorilo mesto Trnava v roku 2020 s cieľom prebudiť v meste počas adventu vianočnú atmosféru aj bez trhov. Vďaka silnému rozprávkového príbehu, krásnym inštaláciám a s pomocou digitálu, sa to podarilo. "),_c('br'),_vm._v(" "),_c('br'),_c('strong',[_vm._v(" V roku 2024 sa Škriatkovo kúzlo presúva do hlavného mesta. Interaktívna hra pre celú rodinu ťa povedie po uličkách a nádvoriach Bratislavy až k vytúženému cieľu. ")]),_c('br')]),_c('img',{staticClass:"mt-6",attrs:{"src":"/images/loga.png","alt":""}})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-offset-1"},[_c('h2',{staticClass:"is-uppercase is-size-3 has-text-weight-light"},[_c('strong',[_vm._v("Kúsok histórie:")])]),_c('h1',{staticClass:"is-size-1 has-text-homered is-family-primary has-text-weight-bold"},[_vm._v(" Škriatkovia domoví"),_c('br'),_vm._v("ochraňovali naše príbytky ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-4 is-offset-2"},[_c('p',[_vm._v(" Naši predkovia kedysi verili, že škriatkovia sú zázračné bytosti, ktoré ochraňujú dom pred chorobami, strážia špajzu a majetok. Dom, do ktorého sa nasťahovali, obývali do konca života. Často sa na ich spokojnosť myslelo pri rôznych sviatkoch, všeobecne štvrtky a na Štedrý večer, kedy sa im do rôznych kútov domu ukladalo jedlo. Do povedomia sa vo veľkom dostali vďaka rozvoju slovenskej národnej literatúry v 19. storočí, kedy o nich písali mnohí významní autori (napríklad Ján Hollý), aj mnohí rozprávkari a zberatelia ľudovej slovesnosti. ")])])
}]

export { render, staticRenderFns }