import Vue from 'vue';
import VueRouter from 'vue-router';
// import NewHome from '../views/NewHome.vue';
import Recipe from '../views/Recipe.vue';
// import Gdpr from '../views/Gdpr.vue';
// import Gdpr2 from '../views/Gdpr2.vue';
// import NewHome from '../views/NewHome.vue';
import RealHome from '../views/RealHome.vue';
import Play from '../views/Play.vue';
import Settings from '../views/Settings.vue';
import Game from '../views/Game.vue';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'HomeNew',
  //   component: NewHome,
  // },
  {
    path: '/susienky',
    name: 'Recipe',
    component: Recipe,
  },

  {
    path: '/',
    name: 'RealHome',
    component: RealHome,
  },
  {
    path: '/play',
    name: 'Play',
    component: Play,
  },
  {
    path: '/nastavenia',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/game',
    name: 'Game',
    component: Game,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
