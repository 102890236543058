<template>
  <div class="home has-text-homeblack is-family-secondary">
    <!-- <Navigation></Navigation> -->
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-4 is-offset-1">
            <img src="/images/sitting-dwarf.png" alt="Skriatkove kuzlo">
          </div>
          <div class="column">
            <h2 class="is-size-1 is-family-primary has-text-homered has-text-weight-bold">
              Recept na maslové sušienky
            </h2>
            <div class="columns mt-6">
              <div class="column is-offset-2 content">
                <p>
                  <ul>
                    <li>Peč v chichotavej atmosfére.</li>
                    <li>Ochutnávať cesto je dovolené.</li>
                    <li>Lepiť si ho na nos vysoko odporúčané.</li>
                    <li>Robiť pri pečení smiešne grimasy takmer nevyhnutné.</li>
                    <li>Sušienkam sa hovorí aj "ykčavorbodu". (čítaj zozadu).</li>
                    <li>Udobríš si nimi každého škriatka v dome.</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section dark">
      <div class="container has-text-centered">

        <h2 class="is-size-1 has-text-homered is-family-primary has-text-weight-bold mb-6">
          Čarovné prísady
        </h2>
        <div class="columns is-mobile is-centered is-multiline">
          <div class="column is-6-mobile is-narrow">
            <img src="/images/maslo.png" alt="Skriatkove kuzlo" class="image image-recipe">
          </div>
          <div class="column is-6-mobile is-narrow">
            <img src="/images/cukor.png" alt="Skriatkove kuzlo" class="image image-recipe">
          </div>
          <div class="column is-6-mobile is-narrow">
            <img src="/images/muka.png" alt="Skriatkove kuzlo" class="image image-recipe">
          </div>
          <div class="column is-6-mobile is-narrow">
            <img src="/images/vajce.png" alt="Skriatkove kuzlo" class="image image-recipe">
          </div>
          <div class="column is-6-mobile is-narrow">
            <img src="/images/sol.png" alt="Skriatkove kuzlo" class="image image-recipe">
          </div>
          <div class="column is-6-mobile is-narrow">
            <img src="/images/vonao.png" alt="Skriatkove kuzlo" class="image image-recipe">
          </div>
          <div class="column is-6-mobile is-narrow">
            <img src="/images/hodiny.png" alt="Skriatkove kuzlo" class="image image-recipe">
          </div>
        </div>

      </div>

    </section>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-7">
            <h2 class="is-size-1 has-text-homered is-family-primary has-text-weight-bold">
              Postup
            </h2>
            <div class="columns mt-6">
              <div class="column is-10 is-offset-1 content">
                <p>
                  <ol>
                    <li>Premiešaj v miske cukor, múku a štipku soli.
                      Chvíľu teraz nekýchaj, lebo zasnežíš celú kuchyňu.</li>
                    <li>K práškovej zmesi pridaj vajíčko,
                      mäkučké maslo a pozor - tajnú „ô príchuť“.</li>
                    <li>Odhalíš ju čítaním sprava doľava: <strong>arôk ávonórtiC</strong>.
                    Tvár sa pri tom dôležito.</li>
                    <li>Tak. A teraz môžeš vypracovať krásne hebké cesto.
                      Keď bude ako vankúšik, napchaj ho cukrárskeho vrecka
                      a vytláčaj na plech voňavé sušienky.</li>
                    <li>Vlož ich na 10 minút do piecky.
                      Pri 180 stupňoch rýchlo chytia krásnu zlatožltú farbu a
                      prevoňajú celú Bratislavu.</li>
                    <li>Ešte čerstvé ich môžeš ponúknuť svojmu škriatkovi s pohárom mlieka.</li>
                  </ol>
                  <br>
                  <strong>
                    Škriatok domový má rád kravské, kozie, vtáčie a rastlinné mlieko,
                    napr. z ryže, mandlí, sóje či kokosu.
                    Menej obľúbuje je kyslé mlieko. Vôbec mu nechutí telové a pleťové mlieko.
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div class="column is-4 is-offset-1">
            <img src="/images/17.png" alt="Skriatkove kuzlo" class="image is-fullwidth">
          </div>
        </div>
      </div>

    </section>
    <!-- <section class="section time">
      <div class="container has-text-centered">
        <p class="flower is-family-secondary">
          <strong class="is-size-4 is-uppercase">
            Hru Škriatkovo kúzlo si môžeš v meste zahrať každý deň od 8:00 do 21:00.
          </strong>
          <br>
          <span class="has-text-weigh-light">
            Na konci hry zistíš, aký je tvoj rodinný škriatok, aj ako s ním udržiavať dobré vzťahy.
          </span>
        </p>
      </div>

    </section> -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
// import Footer from '../components/Footer.vue';
// import Navigation from '../components/Navigation.vue';

export default {
  name: 'Recipe',
  components: {
    // Navigation,
    // Footer,
  },
};
</script>

<style lang="scss" scoped>
  @import "~bulma/sass/utilities/initial-variables.sass";
  @import "@/assets/scss/_variables.scss";
  @import "~bulma/bulma";
  @import "@/assets/scss/_home.scss";
</style>
